import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section class="pt-6 pt-md-11 bg-dark">
          <div class="container-lg">
            <div class="row justify-content-center">
              <div class="col-md-10 col-lg-8 text-center text-white">
                <h6 class="text-uppercase text-warning mb-5">Get Shit Done</h6>
                <h1 class="display-3 mb-4">🎉 The Blog</h1>
                <p class="font-size-lg">
                  WFH survival guides, remote work strategies and work-life
                  balance tips from the Reno Collective community.
                </p>
              </div>
            </div>
          </div>
        </section>
        <div class="position-relative">
          <div class="shape shape-fluid-x shape-bottom text-dark pb-18">
            <div class="shape-img pb-8 pb-md-11">
              <svg viewBox="0 0 100 50" preserveAspectRatio="none">
                <path d="M0 0h100v25H75L25 50H0z" fill="currentColor" />
              </svg>
            </div>
          </div>
        </div>
        <section class="pt-10 pt-md-12">
          <div class="container-lg">
            <BlogRoll />
          </div>
        </section>
      </Layout>
    )
  }
}
