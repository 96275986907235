import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div class="row mt-n6">
        {posts &&
          posts.map(({ node: post }) => (
            <div class="col-md-4">
              <div
                class="card card-sm rounded-top-left rounded-bottom-right lift lift-lg mt-6"
                key={post.id}
              >
                {post.frontmatter.featuredimage ? (
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: post.frontmatter.featuredimage,
                      alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                    }}
                  />
                ) : (
                  <img
                    class="card-img-top rounded-top-left"
                    src="/img/collective/placeholder.png"
                    alt="Reno Collective Coworking"
                  />
                )}
                <div class="card-body">
                  <h2 class="mb-2">
                    <Link
                      className="title has-text-primary is-size-4"
                      to={post.fields.slug}
                    >
                      {post.frontmatter.title}
                    </Link>
                  </h2>

                  <div class="d-flex align-items-center">
                    <div class="avatar avatar-xs">
                      <img
                        class="avatar-img rounded-circle"
                        src="/img/avatars/rcatom.png"
                        alt="..."
                      />
                    </div>
                    <div class="ml-4 text-left">
                      <p class="small mb-0">Reno Collective</p>
                      <p class="small text-muted mt-n1 mb-0">
                        {post.frontmatter.date}
                      </p>
                    </div>
                  </div>
                  <Link className="stretched-link" to={post.fields.slug} />
                </div>
              </div>
            </div>
          ))}
      </div>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
